<template src="./userScanError.html"></template>

<script>
// import axios from 'axios';
export default {
  name: 'home',
  components: {},
  data() {
    return {
      quotes: null,
    };
  },
  // created(){
  //   axios
  //   .get('https://soco-loyalty-feature-api.sociolabs.io/beauty-quotes?limit=1&sort=-created_at')
  //   .then(res => {
  //   const data = res.data.data;
  // //   console.log(data);
  //   this.quotes = data;
  // });

  // },
  computed: {},
};
</script>

<style lang="scss" scoped>
@import 'userScanError';
</style>
